@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-banner-ad-s- {
  .row {
    padding-top: 0;
    padding-bottom: 40px;
  }
  .ds-top {
    padding: 20px 0;
  }
  img {
    margin: 0 auto;
    width: auto !important;
  }
  .owl-item {
    text-align: center;
  }
}

.entity-banner-ads {
  text-align: center;
  display: inline-block;
  padding: 15px 50px;
  border-width: 2px;
  border-style: solid;
  text-decoration: none;

  h2 {
    font-size: 24px;
    @include breakpoint($desktop) {
      font-size: 34px;
    }
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 5px;
  }

  p {
    font-size: 18px;
    @include breakpoint($desktop) {
      font-size: 24px;
    }
    margin-bottom: 0;
    text-transform: uppercase;
  }


  a:hover {
    text-decoration: none;
  }
}
